import React, {FC, useMemo} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import {useFormikContext} from 'formik';
import Consts, {type InputDetail} from '../../../app/Consts';
import {getDisplayAmountValue, getDisplayCommasValue} from '../../../utils/AmountUtils';
import {ValidMixAndMatchFormValues} from '../../../types';
import TableCellUnitField from '../../Table/TableCellUnitField';
import {mnmDisabled} from '../mixAndMatchUtils';

type Props = {
  inputDetail: InputDetail | undefined;
  groupId: number;
  errors?: {[field: string]: string};
};

const GroupAmountInputs: FC<Props> = ({inputDetail, groupId, errors}) => {
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    setFieldValue,
    values: {groups, groupAmounts = [], type},
  } = bag;
  const disabledFields = mnmDisabled(bag.values).fields;
  const group = groups?.find((x) => x.id === groupId);
  const amountIsFree =
    type &&
    [
      Consts.MixAndMatchTypeEnum.BuyAGetBFree,
      Consts.MixAndMatchTypeEnum.BuyNOfAGetOneFree,
      Consts.MixAndMatchTypeEnum.BuyAPlusBGetCFree,
    ].includes(type);
  const discountAdornment = useMemo(() => {
    if (group?.amountType?.toLowerCase() === 'percentage') {
      return {prefix: '%', placeholder: amountIsFree ? 'Free' : 'Percentage off'};
    } else {
      return {prefix: '$', placeholder: amountIsFree ? 'Free' : 'Add value'};
    }
  }, [group?.amountType, amountIsFree]);

  const updateGroupAmounts = (id: number, name: string, value: string) => {
    // amount should be a number with 2 decimal places, quantity should be a positive whole number
    const regex = name === 'amount' ? /^(-?[\d,]*(?:\.\d{0,2})?)?$/ : /^[\d,]*$/;
    if (value === '' || regex.test(value)) {
      const nextGroupAmounts = [...groupAmounts];
      const groupIndex = nextGroupAmounts.findIndex((x) => x.id === id);
      const groupAmount = {id, [name]: value.replace(/,/g, '')};
      if (groupIndex === -1) {
        setFieldValue('groupAmounts', [...nextGroupAmounts, groupAmount]);
      } else {
        nextGroupAmounts.splice(groupIndex, 1, {
          ...nextGroupAmounts[groupIndex],
          ...groupAmount,
        });
        setFieldValue('groupAmounts', nextGroupAmounts);
      }
    }
  };

  const groupItemIndex = groupAmounts.findIndex((x) => x.id === groupId);
  const {showDiscount, showQuantity, showQuantityLimit} = inputDetail ?? {};
  const noInputs = !showDiscount && !showQuantity && !showQuantityLimit;
  if (!inputDetail || !group || noInputs) {
    return null;
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const {value} = event.target;
    updateGroupAmounts(groupId, name, value);
  };

  const handleOnBlurFormat = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const {value} = event.target;
    if (value) {
      updateGroupAmounts(groupId, name, getDisplayAmountValue(value.replace(/,/g, '')));
    }
  };

  return (
    <Grid container direction="column" spacing={4} justifyContent="space-between" flexWrap="nowrap">
      {showDiscount ? (
        <Grid item xs={6} gap={1}>
          <Typography variant="subtitle2">
            {type === Consts.MixAndMatchTypeEnum.BuyAGetBForDollar
              ? 'Discounted total'
              : 'Discount'}
          </Typography>
          <Stack direction="row" sx={{width: '100%'}}>
            <TableCellUnitField
              disabled
              value={discountAdornment.prefix ?? ''}
              sx={{maxWidth: '2.5rem'}}
            />
            <OutlinedInput
              placeholder={discountAdornment.placeholder}
              onChange={(e) => handleChange(e, 'amount')}
              onBlur={(e) => handleOnBlurFormat(e, 'amount')}
              value={
                amountIsFree
                  ? ''
                  : getDisplayCommasValue(
                      groupAmounts[groupItemIndex]?.amount ?? group.amount ?? ''
                    )
              }
              error={!!errors?.amount}
              disabled={disabledFields.groups.amount}
              inputProps={{
                'data-testid': 'discount-input',
              }}
            />
          </Stack>
          {!!errors?.amount ? <FormHelperText error>{errors.amount}</FormHelperText> : null}
        </Grid>
      ) : null}
      {showQuantity ? (
        <Grid item xs={6} gap={1}>
          <Typography variant="subtitle2">Quantity</Typography>
          <OutlinedInput
            placeholder="Quantity"
            onChange={(e) => handleChange(e, 'quantity')}
            value={getDisplayCommasValue(
              groupAmounts[groupItemIndex]?.quantity ?? group.quantity ?? ''
            )}
            error={!!errors?.quantity}
            disabled={disabledFields.groups.quantity}
            inputProps={{
              'data-testid': 'quantity-input',
            }}
          />
          {!!errors?.quantity ? <FormHelperText error>{errors.quantity}</FormHelperText> : null}
        </Grid>
      ) : null}
      {showQuantityLimit ? (
        <Grid xs={6} item gap={1}>
          <Typography variant="subtitle2">Quantity limit</Typography>
          <OutlinedInput
            placeholder="Add value"
            onChange={(e) => handleChange(e, 'quantityLimit')}
            value={getDisplayCommasValue(
              groupAmounts[groupItemIndex]?.quantityLimit ?? group.quantityLimit ?? ''
            )}
            error={!!errors?.quantityLimit}
            disabled={disabledFields.groups.quantityLimit}
            inputProps={{
              'data-testid': 'quantity-limit-input',
            }}
          />
          {!!errors?.quantityLimit ? (
            <FormHelperText error>{errors.quantityLimit}</FormHelperText>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GroupAmountInputs;
