import React, {useMemo} from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import {useFormikContext} from 'formik';
import Consts from '../../../app/Consts';
import {getDisplayAmountValue, getDisplayCommasValue} from '../../../utils/AmountUtils';
import TableCellUnitField from '../../Table/TableCellUnitField';
import {ValidMixAndMatchFormValues} from '../../../types';

import {mnmDisabled} from '../mixAndMatchUtils';

const BundleAmountInput = () => {
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    setFieldValue,
    values: {amount, amountType, type},
    errors,
    touched,
  } = bag;
  const disabled = mnmDisabled(bag.values).fields.amount;
  const discountAdornment = useMemo(() => {
    if (amountType === Consts.AmountTypeEnum.Percentage) {
      return {prefix: '%', placeholder: 'Percentage off'};
    } else {
      return {prefix: '$', placeholder: 'Add value'};
    }
  }, [amountType]);

  const headerText = useMemo(() => {
    if (type === Consts.MixAndMatchTypeEnum.BuyAPlusBOrMoreForPercentageOffEach) {
      return 'Discount';
    }
    return 'Bundle Value';
  }, [type]);

  const errorMessage = useMemo(() => {
    if (touched?.amount && errors?.amount) {
      return errors.amount;
    }
    return null;
  }, [touched, errors]);

  const updateAmount = (value: string) => {
    // amount should be a number with 2 decimal places, quantity should be a positive whole number
    const regex = /^(-?[\d,]*(?:\.\d{0,2})?)?$/;
    if (value === '' || regex.test(value)) {
      setFieldValue('amount', value.replace(/,/g, ''));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = event.target;
    updateAmount(value);
  };

  const handleOnBlurFormat = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = event.target;
    if (value) {
      updateAmount(getDisplayAmountValue(value.replace(/,/g, '')));
    }
  };

  const displayValue = amount === null || amount === undefined ? '' : getDisplayCommasValue(amount);

  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">{headerText}</Typography>
      <Stack direction="row" sx={{width: '100%'}}>
        <TableCellUnitField
          disabled
          value={discountAdornment.prefix ?? ''}
          sx={{maxWidth: '2.5rem'}}
        />
        <OutlinedInput
          placeholder={discountAdornment.placeholder}
          onChange={handleChange}
          onBlur={handleOnBlurFormat}
          value={displayValue ?? ''}
          error={!!errorMessage}
          disabled={disabled}
        />
      </Stack>
      {!!errorMessage ? (
        <FormHelperText data-testid="bundle-amount-error-text" error>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </Stack>
  );
};

export default BundleAmountInput;
