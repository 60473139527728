import React, {FC} from 'react';
import {useField} from 'formik';
import {styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';

const PREFIX = 'InputField';

const classes = {
  input: `${PREFIX}-input`,
};

const StyledTextField = styled(TextField)(() => ({
  [`& .${classes.input}`]: {
    height: '1rem',
  },
  '& .MuiFormHelperText-root': {
    width: '100%',
  },
}));

type Props = TextFieldProps & {
  errorText?: string;
  name: string;
  regexValidation?: RegExp;
};

const InputField: FC<Props> = ({errorText, name, multiline, regexValidation, ...rest}) => {
  const [field, meta, helpers] = useField(name);
  const {value, onChange, ...fieldProps} = field;

  const {touched, error} = meta;
  const helperText = touched && error ? error : undefined;
  const hasError = !!touched && !!error;

  const styleProps = {
    InputProps: {
      classes: {
        input: multiline ? '' : classes.input,
      },
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true);
    const newValue = event.target.value;
    if (regexValidation && !regexValidation.test(newValue)) {
      helpers.setError(errorText ?? 'Invalid input value');
    }
    helpers.setValue(newValue);
    onChange?.(event);
  };

  return (
    <StyledTextField
      variant="outlined"
      type="text"
      error={hasError}
      helperText={hasError && helperText}
      multiline={multiline}
      value={value ?? ''}
      onChange={handleChange}
      {...fieldProps}
      {...rest}
      {...styleProps}
    />
  );
};

export default InputField;
